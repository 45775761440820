import React, { useState } from "react"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import PageHeader from "../../../components/pageheader"
import GridItem from "../../../components/gridItem"
import CatNav from "../../../components/catnav"
import hero from "../../../images/residential-hero.jpg"
import nashville from "../../../images/dev-nashville.jpg"
import bellwood from "../../../images/dev-bellwood.jpg"
import visalia from "../../../images/dev-visalia.jpg"
import nebraska from "../../../images/dev-nebraska.jpg"
import miami from "../../../images/dev-miami.jpg"
import newVisalia from "../../../images/dev-new-visalia.jpg"

const cats = [{name: "Show All", slug: "/developments/type"}, {name: "Category 1", slug: "/developments/type/1"}, {name: "Category 2", slug: "/developments/type/2"}]

const DevelopmentsTypePage = () => {
  return (
    <Layout activeMenuId={3}>
      <Seo title="Residential" />
      <PageHeader
        hasUnderline
        title="Residential"
        subTitle={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam neque nisi, hendrerit in augue vitae, mollis ornare justo. Etiam et risus at quam interdum porta. Nam malesuada dui ac auctor placerat.`}
        image={hero}
        breadcrumbs={[
          { label: "Home", link: "/" },
          { label: "Developments / Residential", link: null },
        ]}
      />
      <div className="container mx-auto">
        <div className="text-center mb-16">
          <CatNav
            catsList={cats}
            activeCat={cats[0]?.slug}
          />
        </div>
      </div>
      <div className="mb-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <GridItem
            bgImage={nashville}
            label="Nashville"
            link="/developments/type/single"
          />
          <GridItem
            bgImage={bellwood}
            label="Bellwood"
            link="/developments/type/single"
          />
          <GridItem
            bgImage={visalia}
            label="Visalia"
            link="/developments/type/single"
          />
          <GridItem
            bgImage={nebraska}
            label="Nebraska"
            link="/developments/type/single"
          />
          <GridItem
            bgImage={miami}
            label="Miami"
            link="/developments/type/single"
          />
          <GridItem
            bgImage={newVisalia}
            label="New Visalia"
            link="/developments/type/single"
          />
        </div>
      </div>
    </Layout>
  )
}

export default DevelopmentsTypePage
